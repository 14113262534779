// Package imports:
import React, { useEffect, useMemo } from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
// Service imports:
import { GET_NEWSWIRE_URL } from '../../services/config';
import { ErrorMessages } from '../../services/errorMessages';
// Type imports:
import { Fetched } from '../../types/Types';
import { INewsOrg } from '../../types/NewsTypes';

interface IProps {
    symbol: string,
    setNewsOrgName?: (name: string | undefined) => void,
    feedKey?: string,
    sourceKey?: string
}

const NewsOrgInfo: React.FC<IProps> = ({
    symbol,
    setNewsOrgName,
    feedKey,
    sourceKey
}) => {
    const [data, setData] = React.useState<Fetched<INewsOrg[]>>(null);
    const fetchOrganizations = async () => {
        try {
            const url = `${GET_NEWSWIRE_URL()}/Organizations`
            
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: Fetched<INewsOrg[]> = await response.json();
                setData(responseBody)

            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }
    
    const org = useMemo(() => {
        if (data && !(data instanceof Error)) {
            const org = data.find(org => org.symbol === symbol);
            return org
        }
        return null;
    }, [data])

    useEffect(() => {
        if (org && setNewsOrgName) {
            setNewsOrgName(org.name);
        }
    }, [org, setNewsOrgName])

    React.useEffect(() => {
        fetchOrganizations();
    }, [])

    if (org === undefined || org === null) {
        return null;
    }

    return (
        <DisplayBox
            className='KCL_news-org-info'
            footerLeft={
                feedKey && sourceKey
                    ? <Link
                        url={`/Kauphallartilkynningar/${feedKey}?sourceKey=${sourceKey}`}
                        linkSize='15'
                        targetBlank
                        icon='forward'
                    >
                        Allar tilkynningar
                    </Link>
                    : undefined
            }
        >
            {org.logoUrl && (
                <div className='logo-wrapper'>
                    <img className='org-logo' src={org.logoUrl} alt={org.name} />
                </div>
            )}
            <table className='org-info-table'>
                <tbody>
                    <tr className='info-line'>
                        <th className='info-title'>
                            Nafn
                        </th>
                        <td>
                            {org.name}
                        </td>
                    </tr>
                    <tr className='info-line'>
                        <th className='info-title'>
                            Vefsíða
                        </th>
                        <td>
                            <Link
                                url={org.websiteUrl || ''}
                                linkSize='15'
                                targetBlank
                            >
                                {org.websiteUrl}
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className='org-description'>
                {org.description}
            </p>
        </DisplayBox>
    );
}

export default NewsOrgInfo;